import { render, staticRenderFns } from "./ProductionBrain.vue?vue&type=template&id=7765cc89&scoped=true"
import script from "./ProductionBrain.vue?vue&type=script&lang=js"
export * from "./ProductionBrain.vue?vue&type=script&lang=js"
import style0 from "./ProductionBrain.vue?vue&type=style&index=0&id=7765cc89&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7765cc89",
  null
  
)

export default component.exports