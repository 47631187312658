<template>
  <div class="procard_wrap">
    <div class="procard_btn">
      <div
        class="procard_item"
        v-for="(item, index) in procardList"
        :key="index"
        :class="activeIndex === index ? 'active' : ''"
        @click="changeIndex(index)"
      >
        {{ item.tit }}
      </div>
    </div>
    <div class="procard_content">
      <div class="content_left">
        <div class="left_tit">{{ procardTitle }}</div>
        <div class="left_icon_wrap">
          <div
          :style="item.txt == '可租面积、空置天数、到期预警一目了然'||item.txt == '以2D/3D可视化形式还原数据与空间之间的关系，让数据更清晰' ? 'width:100%':''"
            class="icon_item"
            v-for="(item, index) in procardImgList"
            :key="index"
            ref="iconItem"
          >
          
            <i  class="iconfont">{{ item.src }}</i>
            <span>{{ item.txt }}</span>
          </div>
        </div>
        <!-- <router-link class="left_btn hidden-xs-only" to="/demo"
          >立即体验</router-link
        > -->
      </div>
      <div class="content_right">
        <img :src="procardImg" alt=""  :style="procardTitle== '按需对接视频监控；车行道闸；人行闸机；周界防控；能耗系统；智慧消防；智能梯控；环境监测；可视对讲等。' ? 'width:77%':''" />
        
        <!-- <router-link class="left_btn hidden-sm-and-up" to="/demo"
          >立即体验</router-link
        > -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      procardTitle:'应收已收欠费统计；租赁状况；未来合同收入预测；园区收益走势；客户资源统计；物业工单状态、类型、趋势分析等平台使用者自由选择定制。',
      procardImg: require('@/assets/img/government-right-1.png'),
      procardImgList: [
        {
          src: '🧰',
          txt: '物业管理',
        },
        {
          src: '💹',
          txt: '费用管理',
        },
        {
          src: '💳',
          txt: '财务管理',
        },
        {
          src: '👔',
          txt: '客户管理',
        },
        {
          src: '🏢',
          txt: '资源管理',
        },
        {
          src: '📈',
          txt: '合同分析',
        }
      ],
    }
  },
  props: {
    procardList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    changeIndex(index) {
      this.activeIndex = index
      switch (this.activeIndex) {
        case 1:
          this.procardTitle =
            '支持矩形示例、楼层布局二维图纸、三维示例三种房源展示方式切换，关联房间信息、合同列表、服务信息、收费信息一键查看'
          this.procardImg = require('../../assets/img/government-right-2.png')
          this.procardImgList = [
            {
              src: '🏬',
              txt: '房源动态，实时更新',
            },
            {
              src: '💡',
              txt: '可租面积、空置天数、到期预警一目了然',
            }
       
          ]
          this.$refs.iconItem.map(v=>{
            v.style.width='100%'
          })
          break
        case 2:
          this.procardTitle =
            '对园区内的楼宇、单元、房源、停车场、车位、设备、仓储等进行管理维护，提供物业资源的层级创建和展示'
          this.procardImg = require('../../assets/img/government-right-3.png')
          this.procardImgList = [
            {
              src: '🏢',
              txt: '物业资源',
            },
            {
              src: '🖥️',
              txt: '设备资源',
            },
            {
              src: '📦',
              txt: '仓储资源',
            }
          ]
          this.$refs.iconItem.map(v=>{
            v.style.width='30%'
          })
          break
        case 3:
          this.procardTitle =
            '按需对接视频监控；车行道闸；人行闸机；周界防控；能耗系统；智慧消防；智能梯控；环境监测；可视对讲等。'
          this.procardImg = require('../../assets/img/right2.png')
          this.procardImgList = [
            {
              src: '📁',
              txt: '将单个类目数据做统计分析，为工作做数据基础',
            },
            {
              src: '🗂️',
              txt: '以2D/3D可视化形式还原数据与空间之间的关系，让数据更清晰',
            },
          ]
          this.$refs.iconItem.map(v=>{
            v.style.width='100%'
          })
          break
        case 4:
          this.procardTitle =
            '按需构建园区的三维模型，融合物联网、建筑等多样数据，采用三维引擎高逼真优化与仿真，辅助管理者决策 。'
          this.procardImg = require('../../assets/img/right3(1).png')
          this.procardImgList = [
            {
              src: '🔮',
              txt: '通过虚拟映射技术，实时监控园区设备、能源、安防等全要素实现智能化管理与科学决策支持',
            }
          ]
          this.$refs.iconItem.map(v=>{
            v.style.width='100%'
          })
          break
        default:
          this.procardTitle =
            '应收已收欠费统计；租赁状况；未来合同收入预测；园区收益走势；客户资源统计；物业工单状态、类型、趋势分析等平台使用者自由选择定制。'
          this.procardImg = require('../../assets/img/government-right-1.png')
          this.procardImgList = [
        {
          src: '🏢',
          txt: '物业管理',
        },
        {
          src: '💹',
          txt: '费用管理',
        },
        {
          src: '💳',
          txt: '财务管理',
        },
        {
          src: '👔',
          txt: '客户管理',
        },
        {
          src: '🏢',
          txt: '资源管理',
        },
        {
          src: '📈',
          txt: '合同分析',
        }
      ],
          this.$refs.iconItem.map(v=>{
            v.style.width='30%'
          })
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.procard_wrap {
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.02) inset;
  @media screen and (max-width: 768px) {
    width: 95%;
    background: #f7f7f7;
  }
  .procard_btn {
    width: 100%;
    display: flex;
    .procard_item {
      flex: 1;
      height: 80px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      position: relative;
      cursor: pointer;
      @media screen and (max-width: 768px) {
        height: 60px;
        text-align: center;
        font-size: 12px;
      }
    }
    .active {
      color: #fff;
      background: #2091d3;
      font-weight: bold;
      &::before {
        content: '▲';
        position: absolute;
        color: #2091d3;
        font-size: 40px;
        transform: rotateX(180deg);
        bottom: -27px;
      }
    }
  }
  .procard_content {
    width: 100%;
    display: flex;
    padding: 30px;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    .content_left {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .left_tit {
        color: #2d3937;
        font-size: 17px;
        min-height: 90px;
        line-height: 1.5rem;
        @media screen and (max-width: 768px) {
          font-size: 0.9rem;
          padding: 10px 20px;
          color: #646464;
        }
      }
      .left_icon_wrap {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 30px;
        @media screen and (max-width: 768px) {
          justify-content: center;
        }
        .icon_item {
          width: 30%;
          height: 50px;
          display: flex;
          align-items: center;
          @media screen and (max-width: 768px) {
            height: 50px;
          }
          i {
            color: #7bbde3;
            font-size: 24px;
            @media screen and (max-width: 768px) {
              font-size: 20px;
            }
          }
          span {
            color: #646464;
            font-size: 16px;
            padding: 0 10px;
            @media screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }
      .left_btn {
        width: 180px;
        height: 50px;
        background: linear-gradient(61deg, #ffb826, #fff01a);;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
      }
    }
    .content_right {
      width: 40%;
      @media screen and (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
      text-align: right;
      img {
        margin-top: 30px;
        width: 50%;
      }
      .left_btn {
        width: 180px;
        height: 50px;
        background: linear-gradient(61deg, #ffb826, #fff01a);;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        color: #fff;
        @media screen and (max-width: 768px) {
          width: 90px;
          height: 35px;
          font-size: 14px;
          border-radius: 20px;
          margin: 20px auto;
        }
      }
    }
  }
}
</style>