<template>
  <el-row class="advantage_wrap">
    <el-col
      class="advantage_item"
      v-for="(item, index) in advantageList"
      :key="index"
      :span="5"
      :xs="11"
    >
      <div class="item_top">
        <div class="icon"><img :src="item.icon" alt=""></div>
        <div class="tit">{{ item.title }}</div>
      </div>
      <ul class="item_desc hidden-xs-only" v-for="(desc, index2) in item.desc" :key="index2">
        <li>{{ desc }}</li>
      </ul>
    </el-col>
  </el-row>
</template>

<script>
export default {
  data() {
    return {
      advantageList: [
        {
          icon: require('../../assets/img/programme/industryInsight/icon/41.png'),
          title: '提升运营效率降低管理成本',
          desc: [
            '通过智能化的任务分配和自动化流程，减少人工干预，显著提升园区运营效率',
            '优化资源配置，减少重复投入，实现园区运营成本的有效控制',
          ],
        },
        {
          icon: require('../../assets/img/programme/industryInsight/icon/42.png'),
          title: '数据驱动决策执行更精准',
          desc: [
            '通过BI技术和数据可视化，实时监控园区运营状态，为管理者提供科学决策支持',
            '实现园区建设的智能化系统和信息化业务系统全域感知及可视化',
          ],
        },
        {
          icon: require('../../assets/img/programme/industryInsight/icon/43.png'),
          title: '业财一体化管理更透明',
          desc: [
            '实现对资产、合同、财务等核心业务的数字化管理，提升管理透明度和可追溯性',
            '实现对楼宇、房源、车位等的全生命周期管控，助力优化提升资产价值与管理效率'
          ],
        },
        {
          icon: require('../../assets/img/programme/industryInsight/icon/44.png'),
          title: '一站式信息互通响应更及时',
          desc: [
            '以用户为中心优化设计，打造互通互协、一站式个性化的园区数字平台',
            '提供丰富便捷的线上服务应用和高效的物业管理，满足各类需求提升企业满意度',
          ],
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.advantage_wrap {
  width: 70%;
  display: flex;
  margin-top: 70px;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }
  .advantage_item {
    height: auto;
    padding: 30px 20px 80px 20px;
    margin-right: 10px;
    background: url('../../assets/img/422c6817d7.png') 0 0 no-repeat;
    background-size: 100% 100%;
    @media screen and (max-width: 768px) {
      margin: 0;
      padding: 0 15px;
      margin-top: 3px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item_top {
      display: flex;
      align-items: center;
      @media screen and (max-width: 768px) {
        height: 100px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
      .icon {
        width: 15%;
        margin-right: 10PX;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          @media screen and (max-width: 768px) {
            margin-bottom: 10px;
            margin-left: 6px;
          }
        }
      }
      .tit {
        width: 100%;
        color: #1c1c1c;
        font-size: 22px;
        font-weight: bold;
        @media screen and (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
    .item_desc {
      font-size: 16px;
      line-height: 20PX;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      padding-left: 34px;
      color: #646464;
      li{
          &:first-child{
            margin-top: 30px;
        }
      }
    }
  }
}
</style>